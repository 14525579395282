<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('faqs.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/faq'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('faqs.create')"
        createRouteName="generalSettings.faqCreate"
        :excelFields="excelFields"
        excelName="faqs"
        :downloadSampleStatus="true"
        downloadSampleUrl="faq/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="faq/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import FAQFilter from "../models/FAQFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new FAQFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "question", label: this.$t("faqs.question"), sortable: true },
        { key: "answer", label: this.$t("faqs.answer"), sortable: true },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "generalSettings.faqView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "generalSettings.faqUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("faqs.item"),
              textContent: "address",
              url: "admin/faq",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("faqs.question")]: "question",
        [this.$t("faqs.answer")]: "answer",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    cityId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.city_id = this.cityId;
  },
};
</script>
